<template>
  <a-select
    show-search
    allowClear
    :placeholder="$t(`请输入`) + '/' + $t(`选择`) + $t(`品牌`)"
    :defaultValue="select"
    v-model="choosed"
    :disabled="disabled"
    option-filter-prop="children"
    :filter-option="filterOption"
    :not-found-content="loading ? undefined : null"
    @popupScroll="scroll"
    @search="search"
  >
    <a-spin v-if="loading" slot="notFoundContent" size="small" />
    <a-select-option v-for="item in brandList" :value="item.brandId" :key="item.brandId">
      {{ item.name }}
    </a-select-option>
  </a-select>
</template>

<script>
import http from '../service/axios'
import api from '../service/api'
import debounce from '@common/debounce'

export default {
  props: [
    'compId',
    'select',
    'placeholder',
    'disabled',
    'goodsId',
    'platform',
    'primaryCategoryId',
  ],
  data() {
    return {
      brandList: [], // 搜索商品列表
      myPlaceholder: '',
      choosed: '',
      choosedName: '',
      totalPages: 0,
      pageNo: 1,
      loading: false,
    }
  },
  mounted() {
    this.myPlaceholder = 'No Brand'
    if (this.select) {
      this.choosed = this.select
    }
  },
  watch: {
    choosed(val) {
      this.brandList.forEach((item) => {
        if (item.brandId == val) {
          this.choosedName = item.name
        }
      })
      this.$emit('selected', val, this.choosedName)
    },
    select(val) {
      this.choosed = val
      this.choosedName = val
    },
    platform(val) {
      if (val) {
        this.fetchBrandList()
      }
    },
  },
  methods: {
    scroll(value) {
      const { scrollHeight, scrollTop, clientHeight } = value.target
      if (this.pageNo < this.totalPages && scrollHeight - scrollTop === clientHeight) {
        this.pageNo++
        this.fetchBrandList()
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    search(value) {
      this.brandList = []
      this.fetchBrandList(value)
    },
    fetchBrandList: debounce(function (value) {
      try {
        this.loading = true
        http({
          url: api.queryLazadaBrand,
          type: 'post',
          data: {
            name: value,
            pageNo: this.pageNo,
            pageSize: 50,
            platform: this.platform,
            goodsId: this.goodsId,
            primaryCategoryId: this.primaryCategoryId,
          },
          hasLoading: false,
          success: (res) => {
            this.brandList.push(...res.result.list)
            this.totalPages = res.result.pages
            this.loading = false
          },
          fail: () => {
            this.loading = false
          },
        })
      } catch (err) {
        console.log(err)
      }
    }, 800),
  },
}
</script>
